
import { Component, Vue } from 'vue-property-decorator'
import DashboardViewer from '@/components/DashboardViewer/DashboardViewer.vue'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'

/**
 * Logik zum Editieren eines bestimmten Dashboards
 */
@Component({
  components: {
    BaseFrame,
    DashboardViewer
  }
})
export default class DashboardEdit extends Vue {
  /**
   * Liest die Dashboard-ID aus der Route aus.
   *
   * @returns ID des Dashboards.
   */
  public get id(): number {
    const entryID = parseInt(this.$route.params.id, 10)

    if (isNaN(entryID)) {
      throw new Error('Dashboard ID must be a number.')
    }

    if (entryID < 1) {
      throw new Error('Dashboard ID must be > 0.')
    }

    return entryID
  }
}
